/** @jsx jsx */

import { jsx, Box, Heading } from "theme-ui"
import { FC, Fragment } from "react"
import { Rows, ViewElement } from "../components/atoms"
import {
  Connection,
  ContentFrontmatter,
  ContentNode,
  Edge,
} from "../data/content"
import { Grid } from "theme-ui"
import { PrimaryFeatured, SecondaryFeatured } from "./featured"
import {
  ContentBlock,
  HeaderSpacing,
  MobileContentBlock,
  MovementContentBlock,
} from "./page"

export const IndexPageContainer: FC<{
  title: string
  color?: string
  bg?: string
  heading: any
  icon?: any
  isRtl?: boolean
  extra?: any
  marginBelow?: boolean
}> = ({
  title,
  heading,
  children,
  color,
  bg,
  icon,
  isRtl,
  extra,
  marginBelow = true,
}) => (
  <Fragment>
    <Rows gap={marginBelow ? [2, null, 5] : 0}>
      <Box sx={{ bg }}>
        <HeaderSpacing />
        <ContentBlock>
          <div>
            <Box sx={{ float: isRtl ? "left" : "right" }}>{icon}</Box>
            <Heading variant="headings.1" sx={{ color }}>
              {title}
            </Heading>
          </div>
          <div
            sx={{
              fontFamily: "monospace",
              fontSize: [13, null, 15],
              maxWidth: ["100%", 500],
              my: [2, null, 3],
            }}
          >
            {heading}
          </div>

          {extra}
        </ContentBlock>
      </Box>

      {children}
    </Rows>
  </Fragment>
)

export const MovementPageContainer: FC<{
  title: string
  color?: string
  bg?: string
  heading: any
  icon?: any
  isRtl?: boolean
  extra?: any
  marginBelow?: boolean
}> = ({
  title,
  heading,
  children,
  color,
  bg,
  icon,
  isRtl,
  extra,
  marginBelow = true,
}) => (
  <Fragment>
    <Rows gap={marginBelow ? [2, null, 5] : 0}>
      <Box sx={{ bg }}>
        <HeaderSpacing />
        <MovementContentBlock sx={{ py: "25px !important" }}>
          <div>
            <Box sx={{ float: isRtl ? "left" : "right" }}>{icon}</Box>
            <Heading variant="headings.1" sx={{ color }}>
              {title}
            </Heading>
          </div>
          <div
            sx={{
              fontFamily: "monospace",
              fontSize: [13, null, 15],
              maxWidth: ["100%", 500],
              my: [2, null, 3],
            }}
          >
            {heading}
          </div>

          {extra}
        </MovementContentBlock>
      </Box>

      {children}
    </Rows>
  </Fragment>
)

export const IndexPageRows: ViewElement<{
  content: Connection<ContentNode>
  withPrimary?: boolean
  columns?: number | number[]
}> = ({
  content: {
    edges: [first, ...rest],
  },
  withPrimary,
  columns = [1, null, 2],
}) => (
  <MobileContentBlock>
    <Rows gap={5} sx={{ width: "100%" }}>
      {withPrimary && <PrimaryFeatured node={first.node} />}

      <Grid gap={5} columns={columns}>
        {(withPrimary ? [] : [first]).concat(rest).map(({ node }) => (
          <SecondaryFeatured key={node.id} node={node} />
        ))}
      </Grid>
    </Rows>
  </MobileContentBlock>
)

export const IndexPageRowsCompact: ViewElement<{
  content: Connection<ContentNode>
}> = ({ content }) => (
  <Rows gap={5}>
    {content.edges.map(({ node }) => (
      <PrimaryFeatured key={node.id} node={node} />
    ))}
  </Rows>
)

export const IndexPageRowsCompactFlexible: ViewElement<{
  content: Edge<ContentNode<ContentFrontmatter>>[]
}> = ({ content }) => (
  <Rows gap={5}>
    {content.map(({ node }) => (
      <PrimaryFeatured key={node.id} node={node} />
    ))}
  </Rows>
)
