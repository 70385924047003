import { PageRoot, ContentNode, Connection, ContentFrontmatter, Edge } from "../data/content"
import { IndexPageContainer, IndexPageRows } from "../components/indexes"
import React, { Fragment, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation, Trans } from 'react-i18next';
import { Columns, Rows } from "../components/atoms"
import SEO from '../components/seo';
import { PillarLogo } from '../images/pillars';
import { MobileContentBlock, ContentBlock, HeaderSpacing } from '../components/page';
import { Checkbox, Grid, Label, Select, Box, Flex, Heading } from "theme-ui"
import { TertiaryFeatured, Localised, useLocalisedContent, PrimaryFeatured, SecondaryFeatured, LatestPrimaryFeatured, SecondaryFeaturedLoader } from '../components/featured';
import { Chip } from '../components/atoms'
import { keys, groupBy } from 'lodash'
import { useTextDirection } from "../i18n";
import { getLanguageName } from "../data/languages";
import { colourMidpoint } from "../data/methods";
import { PillarFiltersStyling, FilterStyling } from "../components/joinform/elements";
import languages from "../data/languages";
import { ImageCard } from '../components/atoms'
import { ObservatorySignupAction } from "../components/actions";


const ObservatoryIndexPage: PageRoot<{ content: Connection<ContentNode> }> = ({
  data: { content }, location: { pathname }
}) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const unintrusiveColour = "#6a6a6a"

  const fadedColour = colourMidpoint("#f2cce6", "#fb174c")

  const groupedArticlesByTopic = groupBy(content.edges, (article) => {
    return article.node.frontmatter.topic?.id
  })
  const groupedArticlesByCountry = groupBy(content.edges, (article) => {
    return article.node.frontmatter.country?.id
  })
  const groupedArticlesBySource = groupBy(content.edges, (article) => {
    return article.node.frontmatter.source?.id
  })

  let availableLocales: string[] = []

  content.edges.forEach(function (article) {
    article.node.fields.localePaths?.forEach(function (localePath) {
      if (!availableLocales.includes(localePath.locale)) {
        availableLocales.push(localePath.locale)
      }
    })
  })

  availableLocales.sort()

  let groupedArticlesByLanguageDictionary: { [id: string]: Edge<ContentNode<ContentFrontmatter>>[]; } = {}

  availableLocales.forEach(locale => {
    groupedArticlesByLanguageDictionary[locale] = []
  })

  content.edges.forEach(article => {
    availableLocales.forEach(locale => {
      if (article.node.fields.localePaths?.map(localePath => localePath.locale).includes(locale)) {
        groupedArticlesByLanguageDictionary[locale].push(article)
      }
    })
  })

  const groupedArticlesByLanguage = groupedArticlesByLanguageDictionary

  const [state, setState] = useState({
    filters: {
      selectedTopicId: "",
      selectedCountryId: "",
      selectedSourceId: "",
      selectedLocale: ""
    }
  })

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedTopicId: event.target.value }
    setState({ filters })
  }

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedCountryId: event.target.value }
    setState({ filters })
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedLocale: event.target.value }
    setState({ filters })
  }

  const handleSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedSourceId: event.target.value }
    setState({ filters })
  }

  const showArticle = (node: ContentNode) => {
    const selectedSourceId = state.filters.selectedSourceId
    const selectedTopicId = state.filters.selectedTopicId
    const selectedCountryId = state.filters.selectedCountryId
    const selectedLocale = state.filters.selectedLocale

    let valid = true

    if (selectedTopicId?.length) {
      if (node.frontmatter.topic?.id != selectedTopicId) {
        valid = false
      } else if (!node.frontmatter.topic) {
        valid = false
      }
    }

    if (selectedCountryId?.length) {
      if (node.frontmatter.country?.id != selectedCountryId) {
        valid = false
      } else if (!node.frontmatter.country) {
        valid = false
      }
    }

    if (selectedLocale?.length) {
      if (!node.fields.localePaths?.map(localePath => localePath.locale).includes(selectedLocale)) {
        valid = false
      } else if (node.fields.localePaths === undefined) {
        valid = false
      }
    }

    if (selectedSourceId?.length) {
      if (node.frontmatter.source?.id != selectedSourceId) {
        valid = false
      } else if (!node.frontmatter.source) {
        valid = false
      }
    }

    return valid
  }

  const toggleFilters = () => {
    try {
      const filterContainer = document.getElementById("filterContainer")

      if (filterContainer?.classList.contains("openFilter")) {
        filterContainer.classList.remove("openFilter")
      } else {
        filterContainer?.classList.add("openFilter")
      }
    } catch (e) {

    }
  }

  const filteredContent = content.edges.filter((value) => {
    return showArticle(value.node);
  });

  return (
    <IndexPageContainer
      title={t(`Observatory`)}
      color="observatoryHex"
      bg="observatoryHexLight"
      heading={
        <Fragment sx={{ marginBottom: "8px" }}>
          <p><Trans>We are building a global observatory with the legal expertise, data science, and parliamentary power to track the attacks on our democratic institutions and provide a real-time defense against them.</Trans></p>
        </Fragment>
      }
      icon={
        <PillarLogo sx={{ color: "observatoryHex", size: [40, 70, 80, 100] }} type={'observatory'} />
      }
      isRtl={dir.isRtl}

      extra={
        <Flex id="filterContainer" sx={PillarFiltersStyling("observatory", fadedColour, unintrusiveColour, dir)}>
          <Label>
            FILTERS
            <div onClick={toggleFilters}><Select disabled></Select></div>
          </Label>
          <Box pl={["0px", "2px", "2px"]} pr={["0px", "4px", "28px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='country'
              onChange={handleCountryChange}
              sx={FilterStyling(state.filters.selectedCountryId, "observatory", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Country')}</option>
              {keys(groupedArticlesByCountry).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {useLocalisedContent(groupedArticlesByCountry[id][0].node.frontmatter.country)?.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box pr={["0px", "6px", "42px"]} sx={{ flex: ['100%', '25%', '25%'] }}>
          </Box>
          <Box pl={["0px", "4px", "28px"]} pr={["0px", "2px", "14px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
          </Box>
          <Box pl={["0px", "6px", "42px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
          </Box>
        </Flex >
      }
    >
      <SEO
        path={pathname}
        title={'Observatory'}
        description={'Observatory.'}
      />

      <ContentBlock sx={{ p: "25px", pb: 0 }}>
        {filteredContent.slice(0, 1).map(({ node }) => (
          <LatestPrimaryFeatured node={node} associatedDate={node.frontmatter.publishDate} />
        ))}

        <SecondaryFeaturedLoader edges={filteredContent.slice(1)} sx={{ mt: ["15px", "15px", "35px"], mb: ["30px", "30px", "0px"] }} />
      </ContentBlock>

      <Box sx={{ bg: 'observatoryHexLight', mt: [2, 2, 3], mb: [3, 4], py: [1, 2] }}>
        <ContentBlock>
          <Grid gap={[3, 4, 5]} columns={[1, 2, 2]} sx={{ gridTemplateColumns: ["minmax(0,1fr)", "minmax(0,0.75fr) minmax(0,1fr)", "minmax(0,0.75fr) minmax(0,1fr)"] }}>
            <Box sx={{ fontSize: "1.3em", lineHeight: "1.2em", letterSpacing: "0.2px" }}>
              <Heading variant='headings.2' sx={{ color: "#FC164B", marginBottom: "1em" }}>
                <Trans>Sign up for more updates</Trans>
              </Heading>
              <Trans>
                Sign up now to learn more and receive updates about the work of the Progressive International Observatory. Leave your phone number to be added to our exclusive broadcast from the frontlines of democratic struggle.
              </Trans>
            </Box>
            <ObservatorySignupAction language={language} />
          </Grid>
        </ContentBlock>
      </Box>

      <Box sx={{ mt: 0, mb: [3, 4], pb: [3, 4] }}>
        <ContentBlock sx={{ p: 4, pt: [3, 3, 0] }}>
          <Heading variant='headings.2' sx={{ mb: "1.5em", color: "#FC164B" }} >
            <Trans>
              Capacities
            </Trans>
          </Heading>
          <Grid gap={[5, null, 9]} columns={[1, 2, 2]}>
            <Box>
              <Grid gap={[2, null, 5]} columns={2}>
                <ImageCard sx={{ position: "relative", width: "100%", pt: "100%" }} src={require("../images/delegations.png")} />
                <Box sx={{ fontSize: ["1.1em", "1.2em", "min(2em,2.5vw)"], alignSelf: "center" }}>
                  <Box sx={{ fontWeight: "600" }}>
                    <Trans>
                      Delegations:
                    </Trans>
                  </Box>
                  <Trans>
                    Organizing International delegations to observe elections in critical country cases.
                  </Trans>
                </Box>
              </Grid>
            </Box>
            <Box>
              <Grid gap={[2, null, 5]} columns={2}>
                <ImageCard sx={{ position: "relative", width: "100%", pt: "100%" }} src={require("../images/analysis.png")} />
                <Box sx={{ fontSize: ["1.1em", "1.2em", "min(2em,2.5vw)"], alignSelf: "center" }}>
                  <Box sx={{ fontWeight: "600" }}>
                    <Trans>
                      Analysis:
                    </Trans>
                  </Box>
                  <Trans>
                    Developing rapid-response data analysis of electoral outcomes.
                  </Trans>
                </Box>
              </Grid>
            </Box>
            <Box>
              <Grid gap={[2, null, 5]} columns={2}>
                <ImageCard sx={{ position: "relative", width: "100%", pt: "100%" }} src={require("../images/investigations.png")} />
                <Box sx={{ fontSize: ["1.1em", "1.2em", "min(2em,2.5vw)"], alignSelf: "center" }}>
                  <Box sx={{ fontWeight: "600" }}>
                    <Trans>
                      Investigations:
                    </Trans>
                  </Box>
                  <Trans>
                    Launching investigations of unlawful interventions in the democratic process.
                  </Trans>
                </Box>
              </Grid>
            </Box>
            <Box>
              <Grid gap={[2, null, 5]} columns={2}>
                <ImageCard sx={{ position: "relative", width: "100%", pt: "100%" }} src={require("../images/communications.png")} />
                <Box sx={{ fontSize: ["1.1em", "1.2em", "min(2em,2.5vw)"], alignSelf: "center" }}>
                  <Box sx={{ fontWeight: "600" }}>
                    <Trans>
                      Communications:
                    </Trans>
                  </Box>
                  <Trans>
                    Building a global network to fight disinformation with findings from our research.
                  </Trans>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </ContentBlock>
      </Box>
    </IndexPageContainer >
  )
}

export const pageQuery = graphql`
  query ObservatoryIndexPage {
    content: allMarkdownRemark(
      filter: { fields: { type: { eq: "observatory-articles" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default ObservatoryIndexPage